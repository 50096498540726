import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Fragment } from "react";
import { Popover, Transition, Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../assets/logo/logo.png";
import blackLogo from "../assets/logo/blackLogo.png";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const solutions = [
  {
    name: "Metaship",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "/solution/metaship",
  },
  // {
  //   name: "NSBA",
  //   description: "Speak directly to your customers in a more meaningful way.",
  //   href: "/solution/nsba",
  // },
  {
    name: "Accident Re-creation",
    description: "Your customers' data will be safe and secure.",
    href: "/solution/accident",
  },
  {
    name: "Offline Learning Platform",
    description: "Connect with third-party tools that you're already using.",
    href: "/solution/offline",
  },
];

const variants = {
  hidden: { opacity: 0, x: 0, y: -300, delay: 1 },
  enter: { opacity: 1, x: 0, y: 0, delay: 1 },
  exit: { opacity: 0, x: 0, y: 0, delay: 1 },
};

const Header = ({ isBackgroundWhite }) => {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 200)
      );
    }
  }, []);
  const colorHandler =
    (!isBackgroundWhite && !small) || small
      ? "text-white hover:text-white"
      : "text-[#17191c] hover:text-[#17191c]";

  return (
    <motion.div
      variants={variants}
      initial="visible"
      animate="enter"
      exit="exit"
      transition={{ type: "linear", duration: 1 }} // Set the transition to linear
      className="z-[60] sticky top-0"
    >
      <Popover
        className={`${
          small
            ? "bg-neutral-900"
            : isBackgroundWhite
            ? "bg-white"
            : "bg-transparent"
        } absolute w-full z-[60] transition-all duration-500 not-italic`}
      >
        <div className="flex items-center justify-between px-4 py-2 sm:px-6 md:justify-start md:space-x-10 font-quicksand">
          <div>
            <a href="/" className="flex">
              <span className="sr-only">Your Company</span>
              <img
                className="h-14 w-auto sm:h-12 relative right-1.5 sm:right-2.5"
                src={(!isBackgroundWhite && !small) || small ? logo : blackLogo}
                alt=""
              />
            </a>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex md:flex-1 md:items-center md:justify-end">
            <Popover.Group
              as="nav"
              className="flex space-x-10 items-center"
              stlye="margin-bottom:5px"
            >
              <a href="/" className={`text-sm ${colorHandler}`}>
                Home
              </a>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-md px-3 py-2 text-sm ${colorHandler} not-italic`}
                    >
                      <span>Solutions</span>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 mt-3 w-[250px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-8 bg-[#17191c] text-white p-7 lg:grid-cols-1">
                            {solutions.map((item) => (
                              <a
                                key={item.name + Math.floor(Math.random() * 10)}
                                href={item.href}
                                className="-m-3 flex text-white items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 hover:text-black focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 hover:text-black"
                              >
                                <div className="ml-4">
                                  <p className="text-sm font-medium">
                                    {item.name}
                                  </p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <a href="/company" className={`text-sm ${colorHandler}`}>
                Company
              </a>
              <a href="/jobs" className={`text-sm ${colorHandler}`}>
                Jobs
              </a>
              <a href="/contact" className={`text-sm ${colorHandler}`}>
                Contact
              </a>
            </Popover.Group>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 h-screen top-0 origin-top-right transform transition md:hidden"
          >
            <div className="divide-y-2 divide-gray-50  bg-neutral-900 shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6 h-screen">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-14 w-auto"
                      src="https://orkainformatics.com/assets/images/logo-white.png"
                      alt="Your Company"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 bg-transparent focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className={"flex flex-col gap-2 w-full py-3"}>
                  <a className="flex items-center rounded-lg pb-3" href={"/"}>
                    <div className="rounded-md w-full text-white h-10 flex items-center text-base">
                      Home
                    </div>
                  </a>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="inline-flex w-full justify-start rounded-md bg-transparent bg-opacity-20 text-white text-base h-10">
                          <span
                            className={
                              "inline-flex w-full justify-start rounded-md bg-transparent bg-opacity-20 text-white text-base"
                            }
                          >
                            Solution
                          </span>
                          <ChevronDownIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-2 text-sm text-gray-500">
                          {solutions.map((item) => {
                            return (
                              <a
                                key={`mobile-${item.name}`}
                                href={item.href}
                                className={`bg-transparent text-white group flex w-full items-center rounded-md text-base shadow-none`}
                              >
                                <div className="rounded-md w-full h-10 pl-2 flex items-center text-base">
                                  {item.name}
                                </div>
                              </a>
                            );
                          })}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  
                  <a
                    className="flex items-center rounded-lg pb-3"
                    href={"/company"}
                  >
                    <div className="rounded-md w-full text-white h-10 flex items-center text-base">
                      Company
                    </div>
                  </a>

                  <a
                    className="flex items-center rounded-lg pb-3"
                    href={"/jobs"}
                  >
                    <div className="rounded-md w-full text-white h-10 flex items-center text-base">
                      Jobs
                    </div>
                  </a>

                  <a
                    className="flex items-center rounded-lg pb-3"
                    href={"/contact"}
                  >
                    <div className="rounded-md w-full text-white h-10 flex items-center text-base">
                      Contact
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </motion.div>
  );
};

export default Header;
