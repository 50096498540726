import React from "react";
import Container from "../../common/Container";
import SolutionCard from "../../common/SolutionCard";
import { solutionsData } from "../../common/constant";

export default function Solutions() {
  return (
    <Container
      title={"Solutions"}
      description={
        "We bring next-generation learning and training experiences to the marine industry."
      }
    >
      <ul className="m-auto my-14 ml-auto mr-auto grid max-w-7xl grid-cols-1 gap-y-20 gap-x-10 lg:grid-cols-3">
        {solutionsData.map((solution) => (
          <SolutionCard
            key={solution.heading}
            heading={solution.heading}
            imageUrl={solution.imageUrl}
            role={solution.role}
            redirectUrl={solution.url}
          />
        ))}
      </ul>
    </Container>
  );
}
