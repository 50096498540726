import { motion } from "framer-motion";
import { motionVariants } from "../common/constant";
import { useEffect } from "react";

const CompanyPage = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-VMPD52ZVEN");
  }, []);
  return (
    <motion.div
      variants={motionVariants} // Pass the variant object into Framer Motion
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: "linear", duration: 1 }} // Set the transition to linear
    >
      <div className="bg-gradient-157 from-[#fff] to-[#f1f2f4]">
        <div className="py-24 sm:py-32 h-full min-h-screen">
          <div className=" pl-4 pr-8 sm:px-6 lg:px-8 flex justify-start flex-col items-start gap-7">
            <h1 className="text-center text-4xl font-light leading-10 tracking-tight text-black sm:text-5xl sm:leading-none lg:text-6xl">
              Orka
            </h1>
            <p className="max-w-3xl text-start text-xl leading-normal text-gray-500">
              Orka is a ‘high energy brand’ led by a young, innovative, creative
              team who has already won the respect of the XR & digital community
              and clients with award-nominated projects and services. Orka has a
              high brand momentum with continuous research and development
              projects, especially for the Maritime Industry. Its potential is
              not limited to marine; Its innovation and content creation
              capabilities are available for all XR and Training projects. Orka
              has proven himself with the quality of different projects since
              2011.
            </p>
            <p className="max-w-3xl text-xl leading-normal text-gray-500">
              Our story began in 2010 when a group of engineers, mariners, 3D
              artists, and software developers worked to develop Project DERIN
              (Realist and Innovative Forms in Marine Industry Training), a
              training system that includes online, computer-based, and virtual
              reality simulation systems. It was a huge success and we decided
              to continue to provide solutions for the Maritime, Logistics,
              Finance, Aviation, and Entertainment Industries.
            </p>
            <p className="max-w-3xl text-xl leading-normal text-gray-500">
              Today; we are proud to say that ORKA is a very successful company
              that not only generates revenues but also increases profits with
              our solution partners who are the leading stakeholders, year after
              year. Orka’s products have run more than 1,8 million individual
              training sessions globally.
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default CompanyPage;
