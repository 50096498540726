import Container from "./Container";

const CustomCard = ({ index, title, description, imageUrl }) => {
  const isEven = index % 2 === 0;
  return (
    <Container isLinear>
      {isEven ? (
        <div
          className={`flex sm:flex-col md:flex-row lg:flex-row w-full gap-8 flex-col-reverse`}
        >
          <div
            className={
              "flex flex-col gap-8 items-start w-full sm:w-full md:w-1/2 lg:w-1/2 font-quicksand"
            }
          >
            <span className={"text-4xl font-light"}>{title}</span>
            {description && (
              <div className={"font-light pr-4 text-lg text-[#464a53]"}>
                {description}
              </div>
            )}
          </div>
          <img
            src={imageUrl}
            className={
              "w-full rounded-2xl object-cover drop-shadow-2xl w-full sm:w-full md:w-1/2 lg:w-1/2 h-fit"
            }
            alt={""}
          />
        </div>
      ) : (
        <div
          className={`flex flex-col sm:flex-col md:flex-row lg:flex-row w-full gap-8`}
        >
          <img
            src={imageUrl}
            className={
              "w-full rounded-2xl object-cover drop-shadow-2xl w-full sm:w-full md:w-1/2 lg:w-1/2 h-fit"
            }
            alt={""}
          />
          <div
            className={
              "flex flex-col gap-8 items-start w-full sm:w-full md:w-1/2 lg:w-1/2"
            }
          >
            <span className={"text-4xl font-light"}>{title}</span>
            {description && (
              <div className={"font-light pr-4 text-lg text-[#464a53]"}>
                {description}
              </div>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};
export default CustomCard;
