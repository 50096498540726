import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
export default function Contact() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-VMPD52ZVEN");
  }, []);

  useEffect(() => {
    setEmailSent(false);
  }, []);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_h7n7fa1",
        "template_sfiuwtw",
        form.current,
        "yalJnMT6EZS1A3tRW"
      )
      .then(
        (result) => {
          setEmail("");
          setMessage("");
          setPhone("");
          setFirstName("");
          setLastName("");
          setCompany("");
          // show the user a success message
          setEmailSent(true);
        },
        (error) => {
          console.log("error", error);
          // show the user an error
        }
      );
  };
  return (
    <div className="bg-gradient-157 from-[#fff] to-[#f1f2f4]">
      <main>
        {/* Header */}
        {!emailSent ? (
          <div className="bg-gradient-157 from-[#fff] to-[#f1f2f4] py-24 sm:py-32">
            <div className="mx-auto max-w-md pl-4 pr-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
              <h1 className="text-center text-4xl font-bold leading-10 tracking-tight text-gray-600 sm:text-5xl sm:leading-none lg:text-6xl">
                Get in touch
              </h1>
              <p className="mx-auto mt-6 max-w-3xl text-center text-xl leading-normal text-gray-500">
                Please fill the form below to inquire about Orka products and
                solutions.
              </p>
            </div>
          </div>
        ) : null}

        {/* Contact Section */}
        <div className="relative bg-white flex justify-center">
          <div className="flex justify-center items-center w-full sm:w-[500px]">
            {!emailSent ? (
              <form
                ref={form}
                onSubmit={sendEmail}
                className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 w-full"
              >
                <div>
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-[#2e3138]"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_name"
                      id="first-name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-2 border-[#f8f8f9] bg-white focus:border-grape-500 focus:ring-grape-500 sm:text-sm p-2 text-[#9095a2]"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-[#2e3138]"
                  >
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_lastname"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-2 border-[#f8f8f9] bg-white focus:border-grape-500 focus:ring-grape-500 sm:text-sm p-2 text-[#9095a2]"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-[#2e3138]"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="user_email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      className="block w-full rounded-md border-2 border-[#f8f8f9] bg-white focus:border-grape-500 focus:ring-grape-500 sm:text-sm p-2 text-[#9095a2]"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-[#2e3138]"
                  >
                    Company
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_company"
                      id="company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      autoComplete="organization"
                      className="block w-full rounded-md border-2 border-[#f8f8f9] bg-white focus:border-grape-500 focus:ring-grape-500 sm:text-sm p-2 text-[#9095a2]"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-[#2e3138]"
                    >
                      Phone
                    </label>
                    <span
                      id="phone-description"
                      className="text-sm text-gray-500"
                    >
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_phone"
                      id="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      autoComplete="tel"
                      aria-describedby="phone-description"
                      className="block w-full rounded-md border-2 border-[#f8f8f9] bg-white focus:border-grape-500 focus:ring-grape-500 sm:text-sm p-2 text-[#9095a2]"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="how-can-we-help"
                      className="block text-sm font-medium text-[#2e3138]"
                    >
                      How can we help you?
                    </label>
                    <span
                      id="how-can-we-help-description"
                      className="text-sm text-gray-500"
                    >
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="how-can-we-help"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      aria-describedby="how-can-we-help-description"
                      rows={4}
                      className="block w-full rounded-md border-2 border-[#f8f8f9] bg-white focus:border-grape-500 focus:ring-grape-500 sm:text-sm p-2 text-[#9095a2]"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="text-right sm:col-span-2">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-grape-700 focus:outline-none focus:ring-2 focus:ring-grape-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <div
                className={"flex justify-center items-center flex-col mt-20"}
              >
                <img
                  src={
                    "https://cdn-icons-png.flaticon.com/512/8968/8968524.png"
                  }
                  alt={"success"}
                  height={200}
                  width={200}
                />
                <p className={"text-2xl sm:text-3xl font-medium"}>
                  Thanks for contacting us!
                </p>
                <p className={"text-base mt-1"}>
                  We will get back to you as soon as possible.
                </p>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
