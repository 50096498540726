import "./App.css";
import Header from "./navigation/Header";
import Footer from "./navigation/Footer";
import Homepage from "./pages/Homepage";
import Product from "./pages/Product";
import MetaShip from "./pages/MetaShip";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Jobs from "./pages/Jobs";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import NsbaPage from "./pages/NsbaPage";
import AccidentPage from "./pages/AccidentPage";
import OfflinePlatformPage from "./pages/OfflinePlatformPage";
import CompanyPage from "./pages/CompanyPage";

function App() {
  const location = useLocation();
  const path = location.pathname.split("/");
  return (
    <AnimatePresence
      location={location}
      key={location.pathname}
      exitBeforeEnter
    >
      <Header
        isBackgroundWhite={[
          "product",
          "nsba",
          "metaship",
          "accident",
          "offline",
          "company",
          "contact",
          "jobs",
        ].includes(path[path.length - 1])}
      />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route
          path="/solution"
          element={<Navigate to="/solution/metaship" />}
        />
        <Route path="/solution/metaship" element={<MetaShip />} />
        <Route path="/solution/nsba" element={<NsbaPage />} />
        <Route path="/solution/accident" element={<AccidentPage />} />
        <Route path="/solution/offline" element={<OfflinePlatformPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/company" element={<CompanyPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/product" element={<Product />} />
        <Route path="/jobs" element={<Jobs />} />
      </Routes>

      <Footer />
    </AnimatePresence>
  );
}

export default App;
