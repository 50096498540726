import Container from "../../common/Container";
import { solutionPartnerData } from "../../common/constant";

const SolutionPartners = () => {
  return (
    <Container
      title={"Solution partners"}
      description={"Our products add value to a variety of solution partners."}
      isLinear
    >
      <div
        className={
          // "mx-auto mt-10 grid max-w-lg grid-cols-1 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-2 sm:gap-x-0 lg:mx-0 lg:max-w-none lg:grid-cols-4"
          // "mx-auto mt-10 grid max-w-lg grid-cols-1 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-2 sm:gap-y-8 sm:gap-x-8 sm:gap-x-0 lg:mx-0 lg:max-w-none lg:grid-cols-4"
          "mx-auto mt-10 grid max-w-lg grid-cols-1 items-center sm:max-w-xl gap-4 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4"
        }
      >
        {solutionPartnerData?.map((partner) => {
          return (
            <div
              // className={`rounded-md bg-cover hover:scale-110 sm:hover:scale-125  text-white cursor-pointer w-full h-full w-60 min-w-full md:min-w-0 h-52 min-h-full md:min-h-0 ease-in-out transition duration-500`}
              className={`rounded-md bg-cover hover:scale-110 sm:hover:scale-110 md:hover:scale-110 text-white cursor-pointer w-full h-full w-60 min-w-full md:min-w-0 h-52 min-h-full md:w-full lg:w-full lg:scale-100 md:min-h-0 ease-in-out transition duration-500`}
              key={partner.heading}
              style={{ backgroundImage: `url('${partner?.imageUrl}')` }}
            >
              <span
                className={
                  "bg-gradient-to-r hover:from-[#cc324766] from-[#3023ae80] hover:to-[#fab53266] to-[#53a0fd80] p-6 flex flex-col h-full w-full rounded-lg justify-end text-2xl"
                }
              >
                {partner.heading}
              </span>
            </div>
          );
        })}
      </div>
    </Container>
  );
};
export default SolutionPartners;
