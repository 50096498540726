import { motion } from "framer-motion";
import { useEffect } from "react";

const variants = {
  hidden: { opacity: 0, x: 0, y: 300 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: 0 },
};

export default function Product() {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-VMPD52ZVEN");
  }, []);
  return (
    <motion.div
      variants={variants} // Pass the variant object into Framer Motion
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: "linear", duration: 1 }} // Set the transition to linear
      className=""
    >
      <div className="relative h-[calc(100vh_-_11rem)] bg-indigo-800">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src="https://orkainformatics.com/assets/images/page-images/metaship/product-placement/banner.png"
            alt=""
          />
          <div
            className="absolute inset-0 bg-indigo-800 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative mx-auto max-w-8xl h-[calc(100vh_-_11rem)] p-6 sm:p-6 flex flex-col justify-center gap-5">
          <h1 className="m-0 text-4xl lg:text-6xl text-left font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Product
          </h1>
          <p className="mt-6 ml-auto ml-0 text-left max-w-3xl text-sm lg:text-2xl text-gray-100">
            Metaship is a digital universe covering digitized vessels, ports,
            waterways, and shipyards where users experience complex systems of
            life and operations onboard.
          </p>
        </div>
      </div>

      {/* Section 1 */}
      <div className="flex overflow-hidden bg-white">
        <div className="mx-auto max-w-8xl flex items-center">
          <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
            <main className="mx-auto mt-10 max-w-8xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-3xl font-bold tracking-normal text-gray-900 sm:text-3xl md:text-4xl">
                  <h2 className="text-sm mb-5 font-semibold text-gray-400 uppercase">
                    Changing regulations
                  </h2>
                  <span className="block text-gray-700 mt-5 xl:inline">
                    High-accuracy vessel models
                  </span>{" "}
                </h1>
                <p className="mt-3 text-base text-gray-800 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                  Metaship offers highly accurate 3D vessel models that cover
                  commercial operational paths like bunkering areas, and
                  loading-discharging ports, provision supply zones, etc.. More
                  than 100 senior seafarers, lecturers, and consultants provided
                  expert advice to make our models as realistic as possible.
                </p>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
            src="https://orkainformatics.com/assets/images/page-images/metaship/product-placement/officer.png"
            alt=""
          />
        </div>
      </div>

      {/* Section 2*/}

      <div className="flex overflow-hidden bg-neutral-100">
        <div className="lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
            src="https://orkainformatics.com/assets/images/page-images/metaship/metaship-platform/why.png"
            alt=""
          />
        </div>
        <div className="mx-auto max-w-8xl flex items-center">
          <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
            <main className="mx-auto mt-10 max-w-8xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-3xl font-bold tracking-normal text-gray-900 sm:text-3xl md:text-4xl">
                  <span className="block text-gray-800 mt-5 xl:inline">
                    Gamification of life and operations onboard
                  </span>{" "}
                </h1>
                <p className="mt-3 text-base text-gray-800 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                  Metaship’s highly realistic models let us design game levels,
                  missions and tasks that mimic the real life scenarios that sea
                  men may experience on a real commercial vessel. Marine
                  professionals and students can master their training on
                  Metaship.
                </p>
              </div>
            </main>
          </div>
        </div>
      </div>

      {/* Section 1 */}
      <div className="flex overflow-hidden bg-white">
        <div className="mx-auto max-w-8xl flex items-center">
          <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
            <main className="mx-auto mt-10 max-w-8xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-3xl font-bold tracking-normal text-gray-900 sm:text-3xl md:text-4xl">
                  <span className="block text-gray-700 mt-5 xl:inline">
                    Revolutionary training system for the marine industry
                  </span>{" "}
                </h1>
                <p className="mt-3 text-base text-gray-800 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                  Metaship’s innovative approach to marine training is extremely
                  beneficial for a range of industries. Maritime classification
                  companies, certification agencies, ship management companies
                  and maritime universities can train their professionals,
                  interns and students in the Metaship world before even setting
                  foot on a real ship.
                </p>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
            src="https://orkainformatics.com/assets/images/page-images/metaship/metaship-platform/how.png"
            alt=""
          />
        </div>
      </div>

      {/* Section 2*/}

      <div className="flex overflow-hidden bg-white">
        <div className="lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
            src="https://orkainformatics.com/assets/images/page-images/metaship/product-placement/officer.png"
            alt=""
          />
        </div>
        <div className="mx-auto max-w-8xl flex items-center">
          <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
            <main className="mx-auto mt-10 max-w-8xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-3xl font-bold tracking-normal text-gray-900 sm:text-3xl md:text-4xl">
                  <span className="block text-gray-700 mt-5 xl:inline">
                    Extensive reporting
                  </span>{" "}
                </h1>
                <p className="mt-3 text-base text-gray-800 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                  Metaship’s reporting module offers a web panel with extensive
                  information about the progress of users. Reporting panel lets
                  you analyze the completion rates of game tasks, the answers to
                  the questions asked during the game and other success metrics.
                </p>
              </div>
            </main>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
