import { motion } from "framer-motion";
import { motionVariants } from "../common/constant";
import { useEffect, useState } from "react";

const jobListings = [
  {
    title: ""
  },
  {
    title: ""
  },

  {
    title: "Unreal Engine Developer",
    content: (

        <div className="py-24 sm:py-4 h-full min-h-screen">
          <div className="pl-4 pr-8 sm:px-6 lg:px-8 flex justify-start flex-col items-start gap-7">
            <h1 className="text-center text-4xl font-light leading-10 tracking-tight text-black sm:text-5xl sm:leading-none lg:text-4xl">
              Unreal Engine Developer
            </h1>
            <h1 className="text-center text-1xl font-bold">Job Description</h1>
            <p className="max-w-3xl">
              Orka is seeking a developer responsible for building games for various target platforms based on the Unreal Engine.
              Your primary responsibilities will be to implement game functionality translating design ideas, concepts,
              and requirements into a functional and engaging game. A commitment to collaborative problem-solving,
              sophisticated design, and a quality product are essential.
            </p>
            <h2 className="text-center text-1xl font-bold">Location</h2>
            <ul>
              <li>London, United Kingdom</li>
              <li>Rotterdam, Netherlands</li>
              <li>Istanbul, Turkey</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Responsibilities</h2>
            <ul>
              <li>- Implement game functionality as per the communicated design</li>
              <li>- Translate design specification into functional game</li>
              <li>- Communicate with other team members to establish an effective pipeline and integrate media assets</li>
              <li>- Design, build, and maintain efficient, reusable, and reliable code</li>
              <li>- Ensure the best possible performance, quality, and responsiveness of applications</li>
              <li>- Identify bottlenecks and bugs, and devise solutions to address and mitigate these problems</li>
              <li>- Help maintain code quality, organization, and automatization</li>
              <li>- Create and iterate new VR and AR gameplay systems for interaction and content creation</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Required Qualifications and Skills (For Mid)</h2>
            <ul>
              <li>- Familiarity with Unreal Engine at least 3 years.</li>
              <li>- Familiarity with C++ and Unreal Blueprint scripting</li>
              <li>- Strong understanding of object-oriented programming</li>
              <li>- Proficient knowledge of Git.</li>
              <li>- The knack for writing clean, readable, and easily maintainable code.</li>
              <li>- Ability to quickly learn and use third-party and internal libraries</li>
              <li>- BS (or higher) in Computer Science, Computer Game Design or a similar discipline (i.e. Physics, Math, EE, etc.)</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Required Qualifications and Skills (For Senior)</h2>
            <ul>
              <li>- Experience with Unreal Engine at least 5 years.</li>
              <li>- Experience with C++ and Unreal Blueprint scripting</li>
              <li>- Strong understanding of object-oriented programming</li>
              <li>- Proficient knowledge of Git.</li>
              <li>- The knack for writing clean, readable, and easily maintainable code.</li>
              <li>- Ability to quickly learn and use third-party and internal libraries</li>
              <li>- BS (or higher) in Computer Science or a similar discipline (i.e. Physics, Math, EE, etc.)</li>
              <li>- AR and/or VR experience as a developer</li>
              <li>- Performance optimization skills</li>
              <li>- Knowledge of database development (MySQL, Oracle, ...)</li>
              <li>- Familiar with JSON</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">What We Offer</h2>
            <ul>
              <li>- Competitive salary and benefits package.</li>
              <li>- Opportunities for professional growth and development.</li>
              <li>- A creative and collaborative work environment.</li>
              <li>- The chance to work on exciting and high-profile projects.</li>
            </ul>
            <p className="max-w-3xl">
              Orka is an equal opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees.
            </p>
            <a href="mailto:official@orkainformatics.com">To apply, email your CV to official@orkainformatics.com</a>
            <p className="max-w-3xl">Join us in building the future of maritime!</p>
          </div>
        </div>
     
    ),
  },
  {
    title: "VFX Artist",
    content: (

        <div className="py-24 sm:py-4 h-full min-h-screen">
          <div className="pl-4 pr-8 sm:px-6 lg:px-8 flex justify-start flex-col items-start gap-7">
            <h1 className="text-center text-4xl font-light leading-10 tracking-tight text-black sm:text-5xl sm:leading-none lg:text-4xl">
              VFX Artist
            </h1>
            <h1 className="text-center text-1xl font-bold">Job Description</h1>
            <p className="max-w-3xl">
              Orka is seeking a skilled and creative Mid-Level VFX Artist to join our dynamic team.
              In this role, you will be responsible for creating high-quality visual effects that enhance the storytelling
              and visual impact of our projects. You will work closely with our senior VFX artists, directors, and producers
              to bring our creative visions to life.
            </p>
            <h2 className="text-center text-1xl font-bold">Location</h2>
            <ul>
              <li>London, United Kingdom</li>
              <li>Rotterdam, Netherlands</li>
              <li>Istanbul, Turkey</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Responsibilities</h2>
            <ul>
              <li>- Create and implement high-quality visual effects for film, game cinematics, and commercial projects.</li>
              <li>- Collaborate with directors, producers, and other team members to understand project requirements and deliver VFX that meet creative and technical standards.</li>
              <li>- Use industry-standard software and tools (e.g., Maya, Houdini, Nuke, After Effects) to create realistic and stylized visual effects.</li>
              <li>- Integrate VFX into live-action footage, ensuring seamless and believable results.</li>
              <li>- Participate in brainstorming sessions and contribute creative ideas for VFX solutions.</li>
              <li>- Troubleshoot and solve technical issues related to VFX production.</li>
              <li>- Maintain a high level of attention to detail and quality throughout the production process.</li>
              <li>- Stay updated with the latest VFX techniques, tools, and industry trends.</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Required Qualifications and Skills</h2>
            <ul>
              <li>- Bachelor's degree in Visual Effects, Animation, Computer Graphics, or a related field.</li>
              <li>- 3-5 years of professional experience as a VFX artist in a film, game cinematics, or commercial production environment.</li>
              <li>- Proficiency in industry-standard VFX software such as Maya, Houdini, Nuke, and After Effects.</li>
              <li>- Strong understanding of VFX pipelines and processes.</li>
              <li>- Demonstrated ability to create high-quality visual effects, including compositing, particle systems, simulations, and dynamics.</li>
              <li>- Strong artistic and technical skills, with a keen eye for detail and realism.</li>
              <li>- Ability to work collaboratively in a team environment and communicate effectively with colleagues and supervisors.</li>
              <li>- Strong problem-solving skills and the ability to adapt to project requirements and deadlines.</li>
              <li>- A portfolio or reel showcasing your VFX work is required.</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">What We Offer</h2>
            <ul>
              <li>- Competitive salary and benefits package.</li>
              <li>- Opportunities for professional growth and development.</li>
              <li>- A creative and collaborative work environment.</li>
              <li>- The chance to work on exciting and high-profile projects.</li>
            </ul>
            <p className="max-w-3xl">
              Orka is an equal opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees.
            </p>
            <a href="mailto:official@orkainformatics.com">To apply, email your CV to official@orkainformatics.com</a>
            <p className="max-w-3xl">Join us in building the future of maritime!</p>
          </div>
        </div>

    ),
  },
  {
    title: "Level Artist for Unreal Engine",
    content: (

        <div className="py-24 sm:py-4 h-full min-h-screen">
          <div className="pl-4 pr-8 sm:px-6 lg:px-8 flex justify-start flex-col items-start gap-7">
            <h1 className="text-center text-4xl font-light leading-10 tracking-tight text-black sm:text-5xl sm:leading-none lg:text-4xl">
              Level Artist for Unreal Engine
            </h1>
            <h1 className="text-center text-1xl font-bold">Job Description</h1>
            <p className="max-w-3xl">
              Orka is seeking a talented and creative Level Artist to join our team. As a Level Artist, you will be responsible for creating immersive and visually compelling environments using Unreal Engine. You will work closely with our game designers, artists, and programmers to bring our game worlds to life.
            </p>
            <h2 className="text-center text-1xl font-bold">Location</h2>
            <ul>
              <li>London, United Kingdom</li>
              <li>Rotterdam, Netherlands</li>
              <li>Istanbul, Turkey</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Responsibilities</h2>
            <ul>
              <li>- Design and create high-quality 3D environments and levels using Unreal Engine.</li>
              <li>- Collaborate with the design team to understand the artistic and gameplay vision for each level.</li>
              <li>- Implement lighting, textures, and visual effects to enhance the overall atmosphere and mood of the game levels.</li>
              <li>- Optimize levels for performance while maintaining visual quality.</li>
              <li>- Work closely with other artists and programmers to integrate assets and ensure a cohesive visual experience.</li>
              <li>- Troubleshoot and resolve issues related to level design and performance.</li>
              <li>- Stay updated with the latest trends and techniques in level design and Unreal Engine.</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Required Qualifications and Skills</h2>
            <ul>
              <li>- Bachelor's degree in Game Design, Art, Computer Graphics, or a related field.</li>
              <li>- 3-5 years of professional experience as a Level Artist or in a similar role.</li>
              <li>- Proficiency in Unreal Engine and its tools for level design and environment creation.</li>
              <li>- Strong understanding of composition, color theory, and lighting techniques.</li>
              <li>- Ability to create detailed and visually appealing environments that align with the game's artistic vision.</li>
              <li>- Experience with 3D modeling and texturing software such as Maya, 3ds Max, or Blender.</li>
              <li>- Strong problem-solving skills and the ability to work effectively in a team environment.</li>
              <li>- A portfolio showcasing your level design and environment creation work is required.</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">What We Offer</h2>
            <ul>
              <li>- Competitive salary and benefits package.</li>
              <li>- Opportunities for professional growth and development.</li>
              <li>- A creative and collaborative work environment.</li>
              <li>- The chance to work on exciting and high-profile projects.</li>
            </ul>
            <p className="max-w-3xl">
              Orka is an equal opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees.
            </p>
            <a href="mailto:official@orkainformatics.com">To apply, email your CV to official@orkainformatics.com</a>
            <p className="max-w-3xl">Join us in building the future of maritime!</p>
          </div>
        </div>

    ),
  },
  {
    title: "QA Analyst",
    content: (

        <div className="py-24 sm:py-4 h-full min-h-screen">
          <div className="pl-4 pr-8 sm:px-6 lg:px-8 flex justify-start flex-col items-start gap-7">
            <h1 className="text-center text-4xl font-light leading-10 tracking-tight text-black sm:text-5xl sm:leading-none lg:text-4xl">
              QA Analyst
            </h1>
            <h1 className="text-center text-1xl font-bold">Job Description</h1>
            <p className="max-w-3xl">
              Orka is seeking a detail-oriented and experienced QA Analyst to join our team. As a QA Analyst, you will be responsible for ensuring the quality and functionality of our software products. You will work closely with our development and design teams to identify issues, perform testing, and ensure a seamless user experience.
            </p>
            <h2 className="text-center text-1xl font-bold">Location</h2>
            <ul>
              <li>London, United Kingdom</li>
              <li>Rotterdam, Netherlands</li>
              <li>Istanbul, Turkey</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Responsibilities</h2>
            <ul>
              <li>- Develop and execute test plans, test cases, and test scripts for software products.</li>
              <li>- Identify, document, and track software defects and issues.</li>
              <li>- Collaborate with development teams to understand product functionality and requirements.</li>
              <li>- Perform manual and automated testing to ensure software quality.</li>
              <li>- Ensure that all software products meet the company's quality standards and specifications.</li>
              <li>- Participate in design and code reviews to provide feedback from a QA perspective.</li>
              <li>- Analyze test results and provide detailed reports to the development team.</li>
              <li>- Continuously improve testing processes and methodologies.</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Required Qualifications and Skills</h2>
            <ul>
              <li>- Bachelor's degree in Computer Science, Information Technology, or a related field.</li>
              <li>- 3-5 years of professional experience as a QA Analyst or in a similar role.</li>
              <li>- Strong understanding of software development and QA methodologies.</li>
              <li>- Proficiency in manual and automated testing tools and techniques.</li>
              <li>- Experience with bug tracking and test management tools.</li>
              <li>- Excellent analytical and problem-solving skills.</li>
              <li>- Strong attention to detail and ability to work in a fast-paced environment.</li>
              <li>- Effective communication and collaboration skills.</li>
              <li>- A portfolio or examples of previous QA work is required.</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">What We Offer</h2>
            <ul>
              <li>- Competitive salary and benefits package.</li>
              <li>- Opportunities for professional growth and development.</li>
              <li>- A creative and collaborative work environment.</li>
              <li>- The chance to work on exciting and high-profile projects.</li>
            </ul>
            <p className="max-w-3xl">
              Orka is an equal opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees.
            </p>
            <a href="mailto:official@orkainformatics.com">To apply, email your CV to official@orkainformatics.com</a>
            <p className="max-w-3xl">Join us in building the future of maritime!</p>
          </div>
        </div>

    ),
  },
  {
    title: "React Frontend Developer",
    content: (

        <div className="py-24 sm:py-4 h-full min-h-screen">
          <div className="pl-4 pr-8 sm:px-6 lg:px-8 flex justify-start flex-col items-start gap-7">
            <h1 className="text-center text-4xl font-light leading-10 tracking-tight text-black sm:text-5xl sm:leading-none lg:text-4xl">
              React Frontend Developer
            </h1>
            <h1 className="text-center text-1xl font-bold">Job Description</h1>
            <p className="max-w-3xl">
              Orka is seeking a skilled and passionate React Frontend Developer to join our team. As a React Frontend Developer, you will be responsible for developing user interfaces for our web applications. You will work closely with our backend developers and UX/UI designers to create seamless and visually appealing user experiences.
            </p>
            <h2 className="text-center text-1xl font-bold">Location</h2>
            <ul>
              <li>London, United Kingdom</li>
              <li>Rotterdam, Netherlands</li>
              <li>Istanbul, Turkey</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Responsibilities</h2>
            <ul>
              <li>- Develop and maintain web applications using React.js.</li>
              <li>- Collaborate with UX/UI designers to translate designs into high-quality code.</li>
              <li>- Implement responsive design and ensure cross-browser compatibility.</li>
              <li>- Optimize applications for maximum speed and scalability.</li>
              <li>- Integrate with RESTful APIs and other backend services.</li>
              <li>- Write clean, maintainable, and efficient code following best practices.</li>
              <li>- Conduct code reviews and provide constructive feedback to team members.</li>
              <li>- Stay updated with the latest industry trends and technologies.</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Required Qualifications and Skills</h2>
            <ul>
              <li>- Bachelor's degree in Computer Science, Software Engineering, or a related field.</li>
              <li>- 3-5 years of professional experience as a Frontend Developer.</li>
              <li>- Proficiency in React.js and its core principles.</li>
              <li>- Strong understanding of HTML, CSS, and JavaScript.</li>
              <li>- Experience with state management libraries such as Redux or MobX.</li>
              <li>- Familiarity with version control systems, such as Git.</li>
              <li>- Excellent problem-solving skills and attention to detail.</li>
              <li>- Ability to work effectively in a team environment and communicate clearly.</li>
              <li>- A portfolio or examples of previous frontend development work is required.</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">What We Offer</h2>
            <ul>
              <li>- Competitive salary and benefits package.</li>
              <li>- Opportunities for professional growth and development.</li>
              <li>- A creative and collaborative work environment.</li>
              <li>- The chance to work on exciting and high-profile projects.</li>
            </ul>
            <p className="max-w-3xl">
              Orka is an equal opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees.
            </p>
            <a href="mailto:official@orkainformatics.com">To apply, email your CV to official@orkainformatics.com</a>
            <p className="max-w-3xl">Join us in building the future of maritime!</p>
          </div>
        </div>

    ),
  },
  {
    title: "Project Manager",
    content: (
        <div className="py-24 sm:py-4 h-full min-h-screen">
          <div className="pl-4 pr-8 sm:px-6 lg:px-8 flex justify-start flex-col items-start gap-7">
            <h1 className="text-center text-4xl font-light leading-10 tracking-tight text-black sm:text-5xl sm:leading-none lg:text-4xl">
              Project Manager
            </h1>
            <h1 className="text-center text-1xl font-bold">Job Description</h1>
            <p className="max-w-3xl">
              Orka is seeking an organized and experienced Project Manager to join our team. As a Project Manager, you will be responsible for planning, executing, and finalizing projects according to deadlines and within budget. You will coordinate with various teams and stakeholders to ensure the successful delivery of projects.
            </p>
            <h2 className="text-center text-1xl font-bold">Location</h2>
            <ul>
              <li>London, United Kingdom</li>
              <li>Rotterdam, Netherlands</li>
              <li>Istanbul, Turkey</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Responsibilities</h2>
            <ul>
              <li>- Define project scope, goals, and deliverables in collaboration with senior management and stakeholders.</li>
              <li>- Develop detailed project plans, including timelines, resource allocation, and budgets.</li>
              <li>- Coordinate cross-functional teams to ensure tasks are completed on time and within scope.</li>
              <li>- Monitor and report on project progress, identifying and addressing potential risks and issues.</li>
              <li>- Manage changes to project scope, schedule, and costs using appropriate verification techniques.</li>
              <li>- Ensure quality assurance and project documentation are maintained throughout the project lifecycle.</li>
              <li>- Communicate project expectations and updates to team members and stakeholders in a clear and timely manner.</li>
              <li>- Conduct post-project evaluations and identify areas for improvement.</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">Required Qualifications and Skills</h2>
            <ul>
              <li>- Bachelor's degree in Project Management, Business Administration, or a related field.</li>
              <li>- 3-5 years of professional experience as a Project Manager.</li>
              <li>- Proven experience in project management methodologies and tools.</li>
              <li>- Strong understanding of project management software, such as MS Project or Asana.</li>
              <li>- Excellent organizational and multitasking skills.</li>
              <li>- Strong communication and interpersonal skills.</li>
              <li>- Ability to work effectively under pressure and meet tight deadlines.</li>
              <li>- PMP or PRINCE2 certification is a plus.</li>
            </ul>
            <h2 className="text-center text-1xl font-bold">What We Offer</h2>
            <ul>
              <li>- Competitive salary and benefits package.</li>
              <li>- Opportunities for professional growth and development.</li>
              <li>- A creative and collaborative work environment.</li>
              <li>- The chance to work on exciting and high-profile projects.</li>
            </ul>
            <p className="max-w-3xl">
              Orka is an equal opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees.
            </p>
            <a href="mailto:official@orkainformatics.com">To apply, email your CV to official@orkainformatics.com</a>
            <p className="max-w-3xl">Join us in building the future of maritime!</p>
          </div>
        </div>

    ),
  },
];

const Jobs = () => {
  const [activeJob, setActiveJob] = useState(null);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    setActiveJob(2);
    gtag("js", new Date());

    gtag("config", "G-VMPD52ZVEN");
  }, []);

  return (
    <motion.div
      variants={motionVariants} // Pass the variant object into Framer Motion
      initial="visible" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: "linear", duration: 1 }} // Set the transition to linear
    >
      <div className="flex">
      <div className="w-1/6 bg-gray-100 p-4   left-0 ">
  <ul>
    {jobListings.map((job, index) => (
      <li key={index} className="mb-4">
        <button
           className="text-grey-500 hover:underline "
          onClick={() => setActiveJob(index)}
        >
          {job.title}
        </button>
      </li>
    ))}
  </ul>
</div>
<div className="w-3/4 ml-1/4 p-4 mt-16">
  {activeJob !== null && jobListings[activeJob].content}
</div>

      </div>
    </motion.div>
  );
};

export default Jobs;
