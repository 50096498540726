import React from "react";
import RecognitionImg from "../../assets/Recognition/recognition_1.png";
import Container from "../../common/Container";
const Recognition = () => {
  return (
    <Container title={"Recognition"}>
      <img src={RecognitionImg} alt={""} className={"w-full mt-10"} />
    </Container>
  );
};
export default Recognition;
