import Accident1 from "../assets/ProductPages/Accident/accident_1.png";
import Accident2 from "../assets/ProductPages/Accident/accident_2.png";
import Accident3 from "../assets/ProductPages/Accident/accident_3.png";
import Accident4 from "../assets/ProductPages/Accident/accident_4.png";
import Nsba1 from "../assets/ProductPages/Nsba/nsba_5.png";
import Nsba2 from "../assets/ProductPages/Nsba/nsba_2.png";
import Nsba3 from "../assets/ProductPages/Nsba/nsba_3.png";
import Nsba4 from "../assets/ProductPages/Nsba/nsba_4.png";
import Offline1 from "../assets/ProductPages/OfflineLearningPlatform/OfflineLearningPlatform_1.png";
import Offline2 from "../assets/ProductPages/OfflineLearningPlatform/OfflineLearningPlatform_2.png";
import Offline3 from "../assets/ProductPages/OfflineLearningPlatform/OfflineLearningPlatform_3.png";
import Offline4 from "../assets/ProductPages/OfflineLearningPlatform/OfflineLearningPlatform_4.png";
import Solutions_1 from "../assets/Solutions/solutions_1.png";
import Solutions_3 from "../assets/Solutions/solutions_3.png";
import Solutions_4 from "../assets/Solutions/solution_6.png";
import solutionPartners_1 from "../assets/SolutionPartners/solutionPartners_1.png";
import solutionPartners_2 from "../assets/SolutionPartners/solutionPartners_2.jpg";
import solutionPartners_3 from "../assets/SolutionPartners/solutionPartners_3.png";
import solutionPartners_4 from "../assets/SolutionPartners/solutionPartners_4.jpg";
import HighAccuracy from "../assets/ProductPages/Metaship/metaship_1.png";
import Accident from "../assets/ProductPages/Metaship/metaship_2.png";
import NSBA from "../assets/ProductPages/Metaship/metaship_3.jpg";
import Offline from "../assets/ProductPages/Metaship/metaship_4.jpg";

export const motionVariants = {
  hidden: { opacity: 0, x: 0, y: 100 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: 0 },
};
export const AccidentData = [
  {
    title: "Importance of accident re-creation",
    description:
      "We specialize in creating detailed 3D cinematics for accident re-creation. We understand that accidents can have a significant impact on your operations and reputation, which is why we are dedicated to providing you with the most accurate and comprehensive re-creation possible.",
    imageUrl: Accident1,
  },
  {
    title: "Collaboration with professionals from the marine industry",
    description:
      "Our team of experienced professionals has a deep understanding of the shipping industry and the factors that can contribute to accidents. We work closely with clients to gather information about the incident and use our state-of-the-art 3D technology to create a realistic and detailed re-creation of the event.",
    imageUrl: Accident2,
  },
  {
    title: "In-depth analysis of accidents",
    description:
      "Our cinematics are designed to provide a thorough understanding of the circumstances surrounding the accident, enabling you to identify the root cause and take appropriate action to prevent similar incidents from occurring in the future. With our detailed 3D simulations, you can effectively analyze the events leading up to the accident and develop strategies to improve safety and mitigate risk.",
    imageUrl: Accident3,
  },
  {
    title: "Attention to detail",
    description:
      "We take pride in our commitment to accuracy and attention to detail, ensuring that every aspect of our re-creation is as close to the real incident as possible. Our team is constantly updating our technology and techniques to ensure that we are at the forefront of the industry.",
    imageUrl: Accident4,
  },
];
export const NSBAData = [
  {
    title: "COLREG (Collision regulations)",
    description:
      "COLREG stands for Collision Regulations, which are a set of international rules for preventing collisions at sea. These regulations, also known as the International Regulations for Preventing Collisions at Sea, were adopted by the International Maritime Organization (IMO) in 1972 and are legally binding for all vessels on the high seas and in waters that are navigable by seagoing vessels.",
    imageUrl: Nsba1,
    subDescription:
      "The COLREG regulations are designed to ensure that vessels maintain a proper lookout, take action to avoid collisions, and follow a set of standardized rules when navigating in shared waters. ",
  },
  {
    title: "COLREG rules in the simulation environment",
    description:
      "NSBA (Navigational Skill and Behavior Assessment Simulation) is a simulation environment that is designed to help professionals in the maritime industry understand and navigate COLREG regulations. NSBA covers a wide range of topics, including the behavior of vessels in different situations, the use of lights and signals, and the responsibilities of vessel operators.",
    imageUrl: Nsba2,
    subDescription:
      "Some of the key focuses of the simulation include maintaining a proper lookout at all times, taking action to avoid collisions even if it means altering course or speed, and giving way to vessels that are restricted in their ability to maneuver. It also aims to assess skills on the regulations that require vessels to use specific lights and signals to communicate their position and intentions to other vessels, and to follow standardized procedures for passing and overtaking other vessels. ",
  },
  {
    title: "Scenario specific questions",
    description:
      "NSBA gives users the ability to walk around the deck freely and explore the vessel’s surroundings.  The simulation includes complex scenarios where the vessel is in high-traffic areas and high navigational skills are required. Various other vessels and buoys are part of the simulation and they need to be carefully observed by the user. ",
    subDescription:
      "The user is allowed to use binoculars to observe other vessels and buoys and the signal lights. The scenario is accompanied by multi-choice questions that need to be answered in a limited time.  Upon answering the questions, the users can see and learn the correct answer.",
    imageUrl: Nsba3,
  },
  {
    title: "Extensive reporting",
    description:
      "NSBA’s reporting module offers a web panel with extensive information about the progress of users. Reporting panel lets you analyze the completion rates of simulation tasks, the answers to the questions asked during the simulation and other success metrics. ",
    imageUrl: Nsba4,
  },
];
export const OfflineData = [
  {
    title: "Specifically designed for onboard use",
    description:
      "Offline Learning Platform is an innovative e-learning tool designed for seafarers. The tool is specifically designed to work offline and can be used onboard, enabling seafarers to access valuable training materials even when they are at sea.",
    imageUrl: Offline1,
  },
  {
    title: "Video-based courses and assessments",
    description:
      "Our e-learning tool consists of a variety of video-based training modules that cover real life ship operations. Each module includes a series of assessments to help seafarers test their knowledge and ensure they are fully prepared to carry out their duties onboard.",
    imageUrl: Offline2,
  },
  {
    title: "Collaboration with professionals from the marine industry",
    description:
      "Our training materials are developed by a team of experienced professionals with deep knowledge of the shipping industry and its specific training needs. We take pride in our commitment to accuracy and attention to detail, ensuring that every aspect of our training materials accurately reflects real world scenarios.",
    imageUrl: Offline3,
  },
  {
    title: "In-line with the industry standards",
    description:
      "We are dedicated to helping seafarers improve their skills and knowledge to enhance safety and efficiency onboard ships. Our e-learning tool is specifically designed to meet the unique training needs of seafarers and is an essential tool for any ship operating in today's complex and rapidly evolving maritime industry.",
    imageUrl: Offline4,
  },
];
export const solutionsData = [
  {
    heading: "Metaship",
    role: "Digital universe covering digitized vessels, ports, waterways, and shipyards.",
    imageUrl: Solutions_1,
    twitterUrl: "#",
    linkedinUrl: "#",
    url: "/solution/metaship",
  },
  // {
  //   heading: "NSBA",
  //   role: "Simulation environment specifically designed to teach COLREG regulations.",
  //   imageUrl: Solutions_2,
  //   twitterUrl: "#",
  //   linkedinUrl: "#",
  //   url: "/solution/nsba",
  // },
  {
    heading: "Accident Re-creation",
    role: "High-quality 3D cinematics for accident re-creation for the marine industry.",
    imageUrl: Solutions_3,
    twitterUrl: "#",
    linkedinUrl: "#",
    url: "/solution/accident",
  },
  {
    heading: "Offline Learning Platform",
    role: "E-learning software for seafarers designed for onboard use.",
    imageUrl: Solutions_4,
    twitterUrl: "#",
    linkedinUrl: "#",
    url: "/solution/offline",
  },
];
export const solutionPartnerData = [
  {
    heading: "Maritime classification companies",
    imageUrl: solutionPartners_1,
  },
  {
    heading: "Certification agencies",
    imageUrl: solutionPartners_2,
  },
  {
    heading: "Ship management companies",
    imageUrl: solutionPartners_3,
  },
  {
    heading: "Maritime universities",
    imageUrl: solutionPartners_4,
  },
];
export const MetashipData = [
  {
    title: "High-accuracy vessel models",
    description:
      "Metaship offers highly accurate 3D vessel models that cover commercial operational paths like bunkering areas, and loading-discharging ports, provision supply zones, etc.. \n" +
      "More than 100 senior seafarers, lecturers, and consultants provided expert advice to make our models as realistic as possible.\n",
    imageUrl: HighAccuracy,
  },
  {
    title: "Gamification of life and operations onboard",
    description:
      "Metaship’s highly realistic models let us design game levels, missions and tasks that mimic the real life scenarios that sea men may experience on a real commercial vessel. Marine professionals and students can master their training on Metaship.",
    imageUrl: Accident,
  },
  {
    title: "Revolutionary training system for the marine industry",
    description:
      "Metaship’s innovative approach to marine training is extremely beneficial for a range of industries. Maritime classification companies, certification agencies, ship management companies and maritime universities can train their professionals, interns and students in the Metaship world before even setting foot on a real ship. ",
    imageUrl: NSBA,
  },
  {
    title: "Extensive reporting",
    description:
      "Metaship’s reporting module offers a web panel with extensive information about the progress of users. Reporting panel lets you analyze the completion rates of game tasks, the answers to the questions asked during the game and other success metrics.",
    imageUrl: Offline,
  },
];
