import React from "react";
import { useNavigate } from "react-router-dom";

const SolutionCard = ({ heading, imageUrl, role, redirectUrl, key }) => {
  const navigate = useNavigate();
  return (
    <ul key={key}>
      <li key={heading} className={"flex flex-col gap-5"}>
        <img
          className="w-full rounded-2xl object-cover drop-shadow-2xl h-56 shadow-lg"
          src={imageUrl}
          alt=""
        />
        <div className="text-[32px] font-quicksand leading-8 text-center tracking-tight text-black font-light">
          {heading}
        </div>
        <p className="text-base font-normal text-center leading-7 text-gray-900 font-quicksand line-clamp-2">
          {role}
        </p>
      </li>
      <div className="mt-3 flex items-center justify-center gap-x-6">
        <button
          onClick={() => navigate(redirectUrl)}
          className="transition-all rounded-md bg-white border border-blue-500 hover:bg-white cursor-pointer px-10 py-2.5 font-quicksand leading-7 text-blue-500 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white text-lg"
        >
          Learn more
        </button>
      </div>
    </ul>
  );
};
export default SolutionCard;
