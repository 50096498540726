import { motion } from "framer-motion";
import Main from "../assets/Solutions/solutions_3.png";
import { AccidentData, motionVariants } from "../common/constant";
import CustomCard from "../common/CustomCard";
import { useEffect } from "react";

const AccidentPage = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-VMPD52ZVEN");
  }, []);
  return (
    <motion.div
      variants={motionVariants} // Pass the variant object into Framer Motion
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: "linear", duration: 1 }} // Set the transition to linear
    >
      <div className="relative h-[calc(100vh_-_11rem)]">
        <div className="absolute inset-0">
          <img className="h-full w-full object-cover" src={Main} alt="" />
          <div
            className="absolute inset-0 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="overlay-custom h-[calc(100vh_-_11rem)] md:w-1/2 sm:w-1/1 absolute z-[40]" />
        <div className="relative mx-auto max-w-8xl h-[calc(100vh_-_11rem)] p-6 sm:p-6 flex flex-col justify-center gap-5 z-50 font-light">
          <h1 className="m-0 text-4xl lg:text-6xl text-left tracking-tight text-white sm:text-5xl lg:text-6xl">
            Accident Re-creation
          </h1>
          <p className=" ml-auto ml-0 text-left max-w-3xl text-sm lg:text-2xl text-gray-100">
            Orka specializes in the production of detailed 3D cinematics for
            marine accident re-creation.
          </p>
        </div>
      </div>
      {AccidentData?.map((product, index) => (
        <CustomCard
          index={index}
          description={product?.description}
          title={product?.title}
          imageUrl={product?.imageUrl}
        />
      ))}
    </motion.div>
  );
};
export default AccidentPage;
