import React from "react";
import Solutions from "../components/home/solutions";
// import Partners from "../components/home/partners";
import SolutionPartners from "../components/home/SolutionPartners";
import Recognition from "../components/home/Recognition";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Homepage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-VMPD52ZVEN");
  }, []);
  return (
    <div className="vimeo-wrapper">
      <div className="relative overflow-hidden d-block z-10 h-screen w-screen">
        <div className="overlay-custom h-screen md:w-1/2 sm:w-1/1 absolute z-[40]"></div>
        <div className="z-50 h-screen md:w-1/2 sm:w-screen p-6 bg-transparent absolute flex justify-center flex-col gap-6">
          <h1 className="text-white  lg:text-6xl text-4xl z-50 font-quicksand font-normal">
            Revolutionary training platform for the maritime industry
          </h1>
          <h2 className="lg:text-2xl text-white text-2xl z-5 font-quicksand font-light">
            Orka’s Metaship training platform offers unmatched realistic
            experience in operating equipment, completing tasks and solving
            problems in a digital marine environment
          </h2>
          <div>
            <button
              className={
                "bg-white text-black p-3 rounded-md font-normal flex flex-row items-center gap-1 min-w-[150px] justify-center"
              }
              onClick={() => navigate("/contact")}
            >
              Contact us
            </button>
          </div>
        </div>
        <iframe
          title="video"
          src="https://player.vimeo.com/video/773517659?h=7e877e28f8&amp;playsinline=0&amp;muted=1&amp;loop=1&amp;background=1&amp;quality=1080p&amp;app_id=122963"
          className="absolute z-10 w-auto min-w-full min-h-full max-w-full"
          height="100%"
          lazyLoad="true"
        ></iframe>
      </div>

      <Solutions />
      <SolutionPartners />
      {/*<Partners />*/}
      <Recognition />
    </div>
  );
};
export default Homepage;
