import React from "react";
const Container = ({ children, title, description, isLinear = false }) => {
  return (
    <div
      className={`py-12 sm:py-24 z-50 font-quicksand ${
        isLinear ? "bg-gradient-157 from-[#fff] to-[#f1f2f4]" : "bg-white "
      }`}
    >
      <div className="m-auto max-w-7xl px-6 lg:px-8">
        {title && (
          <div className="lg:mx-0 text-center m-auto">
            <h2 className="text-3xl font-quicksand tracking-tight text-gray-800 sm:text-[40px]">
              {title}
            </h2>
            {description && (
              <p className="mt-6 text-2xl leading-snug text-[#464a53] font-normal">
                {description}
              </p>
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
export default Container;
